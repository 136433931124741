
import { defineComponent } from '@vue/runtime-core';
import chooserModule, { load } from '@/SharedChart/Chooser/module';

export default defineComponent({
    beforeMount(){
        if(!this.$store.hasModule('chooser'))
            this.$store.registerModule('chooser', chooserModule);
        load();
    },
    computed: {
        collections(){
            return this.$store.state.chooser!.collections;
        }
    }
});
