<template>
    <div id='wrapper'>
        <h1>בחירת שאלון לעריכה</h1>
        <ul>
            <li v-for='{ id, name } in collections' :key='id'>
                <router-link :to='`/questions/collection/${id}`'>
                    {{ name }}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from '@vue/runtime-core';
import chooserModule, { load } from '@/SharedChart/Chooser/module';

export default defineComponent({
    beforeMount(){
        if(!this.$store.hasModule('chooser'))
            this.$store.registerModule('chooser', chooserModule);
        load();
    },
    computed: {
        collections(){
            return this.$store.state.chooser!.collections;
        }
    }
});
</script>

<style lang="scss" scoped>
#wrapper {
    margin: 4rem auto;

}
h1 {
    width: 100%;

}

ul {
    display: grid;
    grid-template-columns: repeat(3, 18rem);
    grid-gap: 2rem 4rem;
    height: fit-content;
    margin-top: 2rem;
}

a {
    background-color: $swatchB;
    border-radius: $corner;
    @include shadow;
    cursor: pointer;
    
    color: inherit;
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem 1rem;

    &:hover {
        background-color: $hoverB;
    }
}
</style>
